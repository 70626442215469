import React, {Component} from 'react';
import {fetchDownloadFile} from "../util/FileUtils";
import {formatCurrencyValue} from "../util/PaymentUtils";
import AccountReport from "./AccountReport";
import { default as getBuyerDisplayValue } from "../components/BuyerTransactionStatus";
import { default as getSupplierDisplayValue } from "../components/SupplierTransactionStatus";
import { getServiceFeeDisplayValue } from "../components/invoices/serviceFeeHelper.js";
import AddRefundCreditForm from "../components/AddRefundCreditForm";
import AddRefundTransactionForm from "../components/AddRefundTransactionForm";
import Busy from "../components/Busy";
import {createLogoutOnFailureHandler} from "../util/LogoutUtil";
import {toast} from "react-toastify";
import moment from "moment";
import URLUtils from "../util/URLUtils";
import {Link, NavLink} from "react-router-dom";
import { requestDenyRefund } from "./requests/booking-requests";
import { requestPatchInvoiceStatus } from '../components/admin/request/invoice-requests';
import {
    invoiceStatusesThatCanBeCancelled,
    invoiceCancelStatuses,
    BookingStatus,
    BookingTransactionTypeLabel,
    BookingTransactionStatusType,
    BookingTransactionType,
    invoicesThatCanBeResent,
    superAdminList
} from '../components/constants/securspace-constants';
import {
    DwollaTransferStatus,
    BookingTransactionStatus,
    PaymentProcessor,
    SubscriptionType
} from '../components/constants/booking-transaction-constants';
import CancelInvoiceNotesInput from '../components/admin/invoices/CancelInvoiceNotesInput';

const $ = window.$;

const chargedButNotPaidOutToPartnerStatuses = [
    BookingTransactionStatusType.PAYMENT_PENDING,
    BookingTransactionStatusType.PAYMENT_SUCCEEDED,
    BookingTransactionStatusType.SECURSPACE_PAYOUT_PENDING,
    BookingTransactionStatusType.SECURSPACE_PAYOUT_SUCCEEDED,
    BookingTransactionStatusType.SECURSPACE_PAYOUT_FAILED,
    BookingTransactionStatusType.SECURSPACE_PAYOUT_CANCELED,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED
];

const DONT_SHOW_INCLUDE_IN_PAYOUT_STATUSES = [
    BookingTransactionStatusType.REFUND_REQUESTED,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_INITIATING,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_PENDING,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_ON_HOLD,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED,
    BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED
];

export default class AdminInvoicesReportOld extends Component {

    constructor(props) {
        super(props);

        this.state = {
            account: this.props.account,
            bookingTransactions: [],
            addRefundCreditTransaction: '',
            addRefundTransaction: '',
            initialSearchText: this.safeGetQueryParameter('bookingNumber') + this.safeGetQueryParameter('transactionNumber'),
            showCancelInvoiceOtherNotes: false,
            transactionId: ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.account !== this.state.account) {
            this.setState({account: nextProps.account});
            this.loadTransactions();
        }
    }

    safeGetQueryParameter(queryString) {
        let queryParameter = URLUtils.getQueryVariable(queryString);
        return queryParameter ? queryParameter : '';
    }

    componentDidMount() {
        this.loadTransactions();
    }

    loadTransactions = () => {
        Busy.set(true);
        $.ajax({
            url: 'api/admins/transactions',
            type: 'GET',
            contentType: 'application/json; charset=UTF-8',
            success: (data) => {
                const formattedTransactions = data.map(transaction => {
                    if (transaction.firstIntervalTransactionEndDate && transaction.firstIntervalTransactionStartDate) {
                        transaction.endDate = transaction.firstIntervalTransactionEndDate;
                        transaction.startDate = transaction.firstIntervalTransactionStartDate;
                    }
                    transaction.combinedAddress = `${transaction.locationAddressLine1}\n${transaction.locationCity}, ${transaction.locationState} ${transaction.locationZip}`;
                    return transaction;
                });
                this.setState({bookingTransactions: formattedTransactions}, () => {
                    Busy.set(false);
                });
            },
            statusCode: {
                401: createLogoutOnFailureHandler(this.props.handleLogout)
            },
            error: (jqXHR) => {
                Busy.set(false);
                let errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message ? jqXHR.responseJSON.message.trim() : "" : "";
                toast.error("Failed to load Transactions:  " + errorMessage);
            }
        });
    };

    downloadInvoice = async (item) => {
        await item?.transactionId && this.downloadInvoiceByTransactionNumber(item?.transactionId);
    };

    downloadInvoiceByTransactionNumber = async (transactionId) => {
        const reportUrl = this?.props?.account?.baseUrl + "/api/invoices/" + transactionId + "/pdf";
        try {
            fetchDownloadFile(reportUrl);
        } catch(error) {
            toast.error(`Unable to generate invoice: ${error}`);
        }
    };

    viewInvoice = (item) => {
        item?.transactionNumber && this.viewInvoiceByTransactionNumber(item?.transactionNumber);
    };

    viewInvoiceByTransactionNumber = (transactionNumber) => {
        window.open(this?.props?.account?.baseUrl + "/admin-invoices?transactionNumber=" + transactionNumber);
    };

    viewInventoryLog = (item) => {
        window.open(this?.props?.account?.baseUrl + '/api/overage-daily-report-by-invoice-number/' + item?.transactionNumber);
    };

    completeRefund = (item, deductFromNextPayout) => {
        Busy.set(true);
        $.ajax({
            url: '/api/booking/complete-refund',
            data: JSON.stringify({
                transactionId: item.transactionId,
                deductFromNextPayout: deductFromNextPayout
            }),
            type: 'POST',
            contentType: 'application/json; charset=UTF-8',
            dataType: "json",
            success: () => {
                Busy.set(false);
                toast.success("Successfully completed refund!");
            },
            statusCode: {
                401: createLogoutOnFailureHandler(this.props.handleLogout)
            },
            error: (jqXHR) => {
                Busy.set(false);
                let errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message ? jqXHR.responseJSON.message.trim() : "" : "";
                toast.error("Failed to complete refund:  " + errorMessage);
            }
        });
    };

    denyRefund = async (item) => {
        Busy.set(true);
        try {
            await requestDenyRefund({ id: item.transactionId });
            toast.success("Successfully denied refund!");
        } catch (error) {
            toast.error(`Failed to deny refund: ${error}`);
        }
        Busy.set(false);
    };

    retryPayment = (item) => {
        Busy.set(true);
        $.ajax({
            url: '/api/booking/retry-charging-transaction',
            data: JSON.stringify({
                bookingTransactionId: item.transactionId
            }),
            type: 'POST',
            contentType: 'application/json; charset=UTF-8',
            dataType: "json",
            success: () => {
                Busy.set(false);
                toast.success("Successfully retried charging failed payment!");
            },
            statusCode: {
                401: createLogoutOnFailureHandler(this.props.handleLogout)
            },
            error: (jqXHR) => {
                Busy.set(false);
                let errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message ? jqXHR.responseJSON.message.trim() : "" : "";
                toast.error("Failed to retry payment:  " + errorMessage);
            }
        });
    };

    toggleIncludeRefundInPayout = (item) => {
        let _this = this;
        Busy.set(true);
        const newIncludeValue = !item.includeRefundInPayout;
        $.ajax({
            url: `/api/booking-transaction/${item.transactionId}/include-refund-in-payout`,
            data: JSON.stringify({
                bookingTransactionId: item.transactionId,
                include: newIncludeValue
            }),
            type: 'POST',
            contentType: 'application/json; charset=UTF-8',
            success: () => {
                Busy.set(false);
                if (newIncludeValue) {
                    toast.success("Successfully included this invoice in the next payout");
                } else {
                    toast.success("Successfully excluded this invoice from the next payout");
                }

                let newBookingTransactions = [..._this.state.bookingTransactions];
                let foundIndex = newBookingTransactions.findIndex(x => x.transactionId === item.transactionId);
                newBookingTransactions[foundIndex].includeRefundInPayout = newIncludeValue;
                _this.setState({bookingTransactions: newBookingTransactions});
            },
            statusCode: {
                401: createLogoutOnFailureHandler(this.props.handleLogout)
            },
            error: (jqXHR) => {
                Busy.set(false);
                let errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message ? jqXHR.responseJSON.message.trim() : "" : "";
                toast.error("Failed to update the invoice:  " + errorMessage);
            }
        });
    };

    putPayoutOnHold = (item) => {
        Busy.set(true);
        $.ajax({
            url: '/api/booking/put-payout-on-hold',
            data: JSON.stringify({
                bookingTransactionId: item.transactionId
            }),
            type: 'POST',
            contentType: 'application/json; charset=UTF-8',
            success: () => {
                Busy.set(false);
                toast.success("Successfully put payout on hold!");
            },
            statusCode: {
                401: createLogoutOnFailureHandler(this.props.handleLogout)
            },
            error: (jqXHR) => {
                Busy.set(false);
                let errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message ? jqXHR.responseJSON.message.trim() : "" : "";
                toast.error("Failed to put payout on hold:  " + errorMessage);
            }
        });
    };

    takePayoutOffHold = (item) => {
        Busy.set(true);
        $.ajax({
            url: '/api/booking/take-payout-off-hold',
            data: JSON.stringify({
                bookingTransactionId: item.transactionId
            }),
            type: 'POST',
            contentType: 'application/json; charset=UTF-8',
            success: () => {
                Busy.set(false);
                toast.success("Successfully took payout off hold!");
            },
            statusCode: {
                401: createLogoutOnFailureHandler(this.props.handleLogout)
            },
            error: (jqXHR) => {
                Busy.set(false);
                let errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message ? jqXHR.responseJSON.message.trim() : "" : "";
                toast.error("Failed to take payout off hold:  " + errorMessage);
            }
        });
    };

    showAddRefundCreditView = (transaction) => {
        this.setState({
            addRefundCreditTransaction: transaction
        })
    };

    showAddRefundTransactionView = (transaction) => {
        this.setState({
            addRefundTransaction: transaction
        })
    };

    closeAllDialogs = () => {
        this.setState({
            addRefundCreditTransaction: null,
            addRefundTransaction: null,
            showCancelInvoiceOtherNotes: false
        });
    };

    addRefundCreditCompleted = (bookingTransaction) => {
        let bookingTransactions = this.state.bookingTransactions;
        let foundIndex = bookingTransactions.findIndex(x => x.id === bookingTransaction.id);
        bookingTransactions[foundIndex] = bookingTransaction;
        this.setState({bookingTransactions: bookingTransactions});
        this.closeAllDialogs();
        toast.success('Successfully added refund credit to invoice!');
    };

    addRefundTransactionCompleted = (refundBookingTransaction) => {
        let refundRequested = BookingTransactionStatusType.REFUND_REQUESTED === refundBookingTransaction.status;
        this.closeAllDialogs();
        if (refundRequested) {
            toast.success('Successfully submitted refund request!  Refund Invoice:  ' + refundBookingTransaction.transactionNumber);
        } else {
            toast.success('Successfully created refund!  Refund Invoice:  ' + refundBookingTransaction.transactionNumber);
        }
    };

    shouldShowCancelOptions = (transaction) => {
        return invoiceStatusesThatCanBeCancelled.includes(transaction.status);
    }

    shouldShowResendServiceChargeOptions = (transaction) => {
        return invoicesThatCanBeResent.includes(transaction.status);
    }

    successCancelInvoice = () => {
        Busy.set(false)
        this.closeAllDialogs()
        toast.success('Successfully Cancelled Invoice!')
    }

    successResendServiceCharge = () => {
        Busy.set(false)
        this.closeAllDialogs()
        toast.success('Successfully Resent Service Charge!')
    }

    failCancelInvoice = (err) => {
        Busy.set(false)
        toast.error(err)
    }

    failResendServiceCharge = (err) => {
        Busy.set(false)
        toast.error(err)
    }

    cancelInvoice = (transaction, status) => {
        Busy.set(true)
        const id = transaction.transactionId;

        requestPatchInvoiceStatus(id, {status: status}, this.successCancelInvoice, this.failCancelInvoice)
    }

    cancelInvoiceWithNotes = (transaction) => {
        this.setState({showCancelInvoiceOtherNotes:true, transactionId: transaction.transactionId})
    }

    toggleCancelInvoiceNotesModal = (boolean) => {
        this.setState({showCancelInvoiceOtherNotes: boolean})
    }

    resendServiceCharge = (transaction, status) => {
        const id = transaction.transactionId;
        requestPatchInvoiceStatus(id, {status: status}, this.successResendServiceCharge, this.failResendServiceCharge)
    }


    render() {
        let addRefundCreditView = (bookingTransactionId) => {
            return (
                <div className="unselectable">
                    <div className="modal-dialog">
                        <div className="modal-content ">
                            <div className="popup-header">
                                <h1>Add Refund Credit To Invoice</h1>
                                <button type="button" className="close pull-right"
                                        aria-label="Close"
                                        onClick={this.closeAllDialogs}>
                                    <img alt="" src="../app-images/close.png"/>
                                </button>
                            </div>


                            <AddRefundCreditForm
                                display="popup"
                                bookingTransactionId={bookingTransactionId}
                                closeSubViewHandler={this.closeAllDialogs}
                                addRefundCreditCompleted={this.addRefundCreditCompleted}
                            />
                        </div>
                    </div>
                </div>
            )
        };
        let addRefundTransactionView = (bookingTransaction) => {
            let chargedButNotPaidOutToPartner = chargedButNotPaidOutToPartnerStatuses.includes(bookingTransaction.status);
            return (
                <div className="unselectable">
                    <div className="modal-dialog">
                        <div className="modal-content ">
                            <div className="popup-header">
                                {
                                    chargedButNotPaidOutToPartner ?
                                        <h1>Create a Refund For This Charge</h1>
                                        :
                                        <h1>Request a Refund For This Charge</h1>
                                }
                                <button type="button" className="close pull-right"
                                        aria-label="Close"
                                        onClick={this.closeAllDialogs}>
                                    <img alt="" src="../app-images/close.png"/>
                                </button>
                            </div>


                            <AddRefundTransactionForm
                                display="popup"
                                bookingTransaction={bookingTransaction}
                                chargedButNotPaidOutToPartner={chargedButNotPaidOutToPartner}
                                closeSubViewHandler={this.closeAllDialogs}
                                addRefundTransactionCompleted={this.addRefundTransactionCompleted}
                            />
                        </div>
                    </div>
                </div>
            )
        };
        let     INVOICE_ACTIONS = [
            {
                displayValue: 'Download Invoice',
                action: this.downloadInvoice
            },
            {
                displayValue: 'View Invoice',
                action: this.viewInvoice
            },
            {
                displayValue: 'View Inventory Log',
                action: this.viewInventoryLog,
            },
            {
                displayValue: 'Add Refund Credit...',
                action: this.showAddRefundCreditView,
                shouldShowAction: (transaction) => {
                    return transaction.status === BookingTransactionStatusType.CHARGE_PENDING;
                }
            },
            {
                displayValue: 'Put Payout On Hold',
                action: this.putPayoutOnHold,
                shouldShowAction: (transaction) => {
                    let refundableStatus = [
                        BookingTransactionStatusType.PAYMENT_PENDING,
                        BookingTransactionStatusType.PAYMENT_SUCCEEDED,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_PENDING,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_SUCCEEDED,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_FAILED,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_CANCELED,
                        BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED,
                        BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED
                    ].includes(transaction.status);

                    let refundableType = ![
                        BookingTransactionType.BOOKING_CHARGE,
                        BookingTransactionType.DATE_ADJUST_CHARGE,
                        BookingTransactionType.ADD_SPACE_CHARGE,
                        BookingTransactionType.OVERAGE_CHARGE,
                        BookingTransactionType.DAILY_OVERAGE_CHARGE,
                        BookingTransactionType.OVERSTAY_CHARGE
                    ].includes(transaction.type);

                    return refundableStatus && refundableType && !transaction.supplierPayoutOnHold;
                }
            },
            {
                displayValue: 'Take Payout Off Hold',
                action: this.takePayoutOffHold,
                shouldShowAction: (transaction) => {
                    let refundableStatus = [
                        BookingTransactionStatusType.PAYMENT_PENDING,
                        BookingTransactionStatusType.PAYMENT_SUCCEEDED,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_PENDING,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_SUCCEEDED,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_FAILED,
                        BookingTransactionStatusType.SECURSPACE_PAYOUT_CANCELED,
                        BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED,
                        BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED
                    ].includes(transaction.status);

                    let refundableType = ![
                        BookingTransactionType.BOOKING_CHARGE,
                        BookingTransactionType.DATE_ADJUST_CHARGE,
                        BookingTransactionType.ADD_SPACE_CHARGE,
                        BookingTransactionType.OVERAGE_CHARGE,
                        BookingTransactionType.DAILY_OVERAGE_CHARGE,
                        BookingTransactionType.OVERSTAY_CHARGE
                    ].includes(transaction.type);

                    return refundableStatus && refundableType && transaction.supplierPayoutOnHold;
                }
            },
            {
                displayValue: 'Retry Failed Payment',
                action: this.retryPayment,
                shouldShowAction: (transaction) => {
                    return transaction.status === BookingTransactionStatusType.PAYMENT_FAILED;
                }
            },
            {
                displayValue: 'Charge Invoice',
                action: this.retryPayment,
                shouldShowAction: (transaction) => {
                    return transaction.status === BookingTransactionStatusType.CHARGE_PENDING;
                }
            }
        ];
        if (superAdminList.includes(this.props.account.username)) {
            INVOICE_ACTIONS.push(
                {
                    displayValue: 'Refund...',
                    action: this.showAddRefundTransactionView,
                    shouldShowAction: (transaction) => {
                        let refundableStatus = [
                            BookingTransactionStatusType.PAYMENT_SUCCEEDED,
                            BookingTransactionStatusType.SECURSPACE_PAYOUT_PENDING,
                            BookingTransactionStatusType.SECURSPACE_PAYOUT_SUCCEEDED,
                            BookingTransactionStatusType.SECURSPACE_PAYOUT_FAILED,
                            BookingTransactionStatusType.SECURSPACE_PAYOUT_CANCELED,
                            BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED,
                            BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED
                        ].includes(transaction.status);

                        return refundableStatus &&  transaction.transferType === 'CHARGE';
                    }
                },
                {
                    displayValue: 'Request Refund...',
                    action: this.showAddRefundTransactionView,
                    shouldShowAction: (transaction) => {
                        let refundableStatus = [
                            BookingTransactionStatusType.SUPPLIER_PAYOUT_PENDING,
                            BookingTransactionStatusType.SUPPLIER_PAYOUT_ON_HOLD,
                            BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED,
                            BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED
                        ].includes(transaction.status);

                        return refundableStatus && transaction.transferType === 'CHARGE';
                    }
                },
                {
                    displayValue: 'Complete Refund (Deduct From Next Payout)',
                    action: (item) => {
                        this.completeRefund(item, true);
                    },
                    shouldShowAction: (transaction) => {
                        return transaction.status === BookingTransactionStatusType.REFUND_REQUESTED;
                    }
                },
                {
                    displayValue: 'Complete Refund (DO NOT Deduct From Next Payout)',
                    action: (item) => {
                        this.completeRefund(item, false);
                    },
                    shouldShowAction: (transaction) => {
                        return transaction.status === BookingTransactionStatusType.REFUND_REQUESTED;
                    }
                },
                {
                    displayValue: 'Deny Refund',
                    action: this.denyRefund,
                    shouldShowAction: (transaction) => {
                        return transaction.status === BookingTransactionStatusType.REFUND_REQUESTED;
                    }
                },
                {
                    displayValue: 'Include Refund In Payout',
                    action: this.toggleIncludeRefundInPayout,
                    shouldShowAction: (transaction) => {
                        return transaction.transferType === 'REFUND' &&
                            !transaction.includeRefundInPayout &&
                            !DONT_SHOW_INCLUDE_IN_PAYOUT_STATUSES.includes(transaction.status);
                    }
                },
                {
                    displayValue: 'Exclude Refund From Payout',
                    action: this.toggleIncludeRefundInPayout,
                    shouldShowAction: (transaction) => {
                        return transaction.transferType === 'REFUND' &&
                            transaction.includeRefundInPayout &&
                            !DONT_SHOW_INCLUDE_IN_PAYOUT_STATUSES.includes(transaction.status);
                    }
                },
                {
                    displayValue: 'Uncollectible',
                    action: (transaction) => this.cancelInvoice(transaction, invoiceCancelStatuses.UNCOLLECTIBLE),
                    shouldShowAction: (transaction) => this.shouldShowCancelOptions(transaction)
                },
                {
                    displayValue: 'Cancel - Term Changes',
                    action: (transaction) => this.cancelInvoice(transaction, invoiceCancelStatuses.CANCEL_TERM_CHANGES),
                    shouldShowAction: (transaction) => this.shouldShowCancelOptions(transaction)
                },
                {
                    displayValue: 'Cancel - Booking Cancelled',
                    action: (transaction) => this.cancelInvoice(transaction, invoiceCancelStatuses.CANCEL_BOOKING_CANCELLED),
                    shouldShowAction: (transaction) => this.shouldShowCancelOptions(transaction)
                },
                {
                    displayValue: 'Cancel - Billing Requirements',
                    action: (transaction) => this.cancelInvoice(transaction, invoiceCancelStatuses.CANCEL_BILLING_REQUIREMENTS),
                    shouldShowAction: (transaction) => this.shouldShowCancelOptions(transaction)
                },
                {
                    displayValue: 'Cancel - Other',
                    action: (transaction) => this.cancelInvoiceWithNotes(transaction),
                    shouldShowAction: (transaction) => this.shouldShowCancelOptions(transaction)
                },
                {
                    displayValue: 'Resend',
                    action: (transaction) => this.resendServiceCharge(transaction, BookingTransactionStatusType.CHARGE_ON_HOLD),
                    shouldShowAction: (transaction) => this.shouldShowResendServiceChargeOptions(transaction)
                }
            )
        }


        return (
            <div className="h-100">
                {this.state.addRefundCreditTransaction ? addRefundCreditView(this.state.addRefundCreditTransaction.transactionId) : ''}
                {this.state.addRefundTransaction ? addRefundTransactionView(this.state.addRefundTransaction) : ''}
                    <CancelInvoiceNotesInput
                        showNotes={this.state.showCancelInvoiceOtherNotes}
                        setShowNotes={this.toggleCancelInvoiceNotesModal}
                        transactionId={this.state.transactionId}
                        successCancelInvoice={this.successCancelInvoice}
                        failCancelInvoice={this.failCancelInvoice}
                    />
                <AccountReport title="Invoices"
                               data={this.state.bookingTransactions}
                               dateField="createdOn"
                               initialSearchText={this.state.initialSearchText}
                               defaultSortBy="transactionNumber"
                               defaultSortByDirection="DESC"
                               defaultDaysInDateRange={30}
                               visibleRecordBatchSize={50}
                               reportFields={[
                                   {
                                       label: "INVOICE NUMBER",
                                       name: "transactionNumber",
                                       groupable: false
                                   },
                                   {
                                       label: "Start Date",
                                       name: "startDate"
                                   },
                                   {
                                       label: "End Date",
                                       name: "endDate"
                                   },
                                   {
                                       label: "CUSTOMER AMOUNT",
                                       name: "buyerAmount",
                                       formatter: (value) => {
                                           return formatCurrencyValue(value);
                                       }
                                   },
                                   {
                                       label: "PAYMENT PROCESSOR AMOUNT",
                                       name: "paymentProcessorFees",
                                       formatter: (value) => {
                                           return formatCurrencyValue(value);
                                       }
                                   },
                                   {
                                       label: "TAX AMOUNT",
                                       name: "transactionTaxAmount",
                                       formatter: (value) => {
                                           return formatCurrencyValue(value ? value : 0, true);
                                       }
                                   },
                                   {
                                       label: "PARTNER AMOUNT",
                                       name: "supplierAmount",
                                       formatter: (value) => {
                                           return formatCurrencyValue(value);
                                       }
                                   },
                                   {
                                       label: "BOOKING STATE",
                                       name: "locationState"
                                   },
                                   {
                                       label: "SECURSPACE AMOUNT",
                                       name: "securspaceFees",
                                       formatter: (value) => {
                                           return formatCurrencyValue(value);
                                       }
                                   },
                                   {
                                       label: "CHARGE TYPE",
                                       name: "transactionType",
                                       formatter: (type) => {
                                           return BookingTransactionTypeLabel[type] || type;
                                       }
                                   },
                                   {
                                       label: "INVOICE CREATED",
                                       name: "createdOn"
                                   },
                                   {
                                       label: "PAYMENT TYPE",
                                       name: "paymentType"
                                   },
                                   {
                                       label: "PAYMENT PROCESSOR",
                                       name: "paymentProcessor",
                                       formatter: (paymentProcessor) => {
                                           return PaymentProcessor[paymentProcessor]?.label;
                                       }
                                   },
                                   {
                                       label: "PAYMENT INITIATED",
                                       name: "paymentCreatedOn"
                                   },
                                   {
                                       label: "PAYMENT COMPLETED",
                                       name: "paymentCompletedOn"
                                   },
                                   {
                                       label: "PAYOUT INITIATED",
                                       name: "payoutCreatedOn"
                                   },
                                   {
                                       label: "PAYOUT COMPLETED",
                                       name: "payoutCompletedOn"
                                   },
                                   {
                                       label: "CUSTOMER",
                                       name: "buyerCompanyName"
                                   },
                                   {
                                       label: "LOCATION",
                                       name: "locationName"
                                   },
                                   {
                                       label: "LOCATION ADDRESS",
                                       name: "combinedAddress"
                                   },
                                   {
                                       label: "PARTNER",
                                       name: "supplierCompanyName"
                                   },
                                   {
                                       label: "BOOKING",
                                       name: "bookingNumber",
                                       link: (invoice) => {
                                           return (
                                               <NavLink to={{
                                                   pathname: '/admin-bookings',
                                                   search: "bookingNumber=" + invoice.bookingNumber
                                               }}>
                                                   {invoice.bookingNumber}
                                               </NavLink>
                                           );
                                       }
                                   },
                                   {
                                       label: "SPACES",
                                       name: "numberOfSpaces"
                                   },
                                   {
                                       label: "BOOKING STATUS",
                                       name: "bookingStatus",
                                       formatter: (bookingStatus) => {
                                           return BookingStatus[bookingStatus];
                                       }
                                   },
                                   {
                                       label: "Equipment Type",
                                       name: "assetType"
                                   },
                                   {
                                       label: "Service Charge Type",
                                       name: "serviceFeeType",
                                       formatter: getServiceFeeDisplayValue
                                   },
                                   {
                                       label: "TRANSACTION STATUS",
                                       name: "status",
                                       formatter: (status) => {
                                           return BookingTransactionStatus[status]?.label;
                                       }
                                   },
                                   {
                                       label: "BUYER TRANSACTION STATUS",
                                       name: "status",
                                       formatter: (status) => {
                                           return getBuyerDisplayValue(status);
                                       }
                                   },
                                   {
                                       label: "SUPPLIER TRANSACTION STATUS",
                                       name: "status",
                                       formatter: (status) => {
                                           return getSupplierDisplayValue(status);
                                       }
                                   },
                                   {
                                       label: "STATUS - NOTES",
                                       name: "statusNotes",
                                       groupable: false
                                    },
                                   {
                                       label: "TAX STATUS",
                                       name: "taxStatus"
                                   },
                                   {
                                       label: "CONTRIBUTES TO REVENUE",
                                       name: "contributesToRevenue",
                                       formatter: (value) => {
                                           return value ? "Yes"  : "No";
                                       }
                                   },
                                   {
                                       label: "PAYOUT ON HOLD",
                                       name: "supplierPayoutOnHold",
                                       formatter: (value) => {
                                           return value ? "True"  : "False";
                                       }
                                   },
                                   {
                                       label: "INCLUDE REFUND IN PAYOUT",
                                       name: "includeRefundInPayout",
                                       formatter: (value) => {
                                           return value ? "True"  : "False";
                                       },
                                       shouldShowField: item => item.transferType === 'REFUND'
                                   },
                                   {
                                       label: "BOOKING CREATED ON",
                                       name: "bookingCreatedOn",
                                       formatter: (value) => {
                                           return moment(new Date(value)).format('MM/DD/YYYY hh:mm A');
                                       }
                                   },
                                   {
                                       label: "BOOKING CREATED BY",
                                       name: "bookingCreatedBy",
                                   },
                                   {
                                       label: "REFUND REQUESTED BY",
                                       name: "refundRequestedBy",
                                       shouldShowField: item => item.transferType === 'REFUND'
                                   },
                                   {
                                       label: "REFUND REASON",
                                       name: "reasonForRefund",
                                       shouldShowField: item => item.transferType === 'REFUND'
                                   },
                                   {
                                       label: "RECOUPABLE",
                                       name: "recoupable",
                                       shouldShowField: item => item.transferType === 'REFUND'
                                   },
                                   {
                                       label: "RECOUPED",
                                       name: "recouped",
                                       shouldShowField: item => item.transferType === 'REFUND'
                                   },
                                   {
                                       label: "Original Invoice",
                                       name: "refundForTransactionNumber",
                                       link: (invoice) => {
                                           return invoice.refundForTransactionNumber ? (
                                               <Link><span onClick={() => this.viewInvoiceByTransactionNumber(invoice.refundForTransactionNumber)}>
                                                   {invoice.refundForTransactionNumber}
                                               </span></Link>
                                           ) : '-';
                                       },
                                       shouldShowField: item => item.transferType === 'REFUND'
                                   },
                                   {
                                       label: "LOCATION IS LIVE",
                                       name: "locationIsLive",
                                       formatter: (value) => {
                                           return value ? "Yes"  : "No";
                                       }
                                   },
                                   {
                                       label: "SUPPLIER SUBSCRIPTION TYPE",
                                       name: "supplierSubscriptionType",
                                       formatter: (subscriptionType) => {
                                           return SubscriptionType[subscriptionType]?.label;
                                       }
                                   },
                                   {
                                       label: "Stripe Payout ID",
                                       name: "stripePayoutId",
                                   },
                                   {
                                       label: "Stripe Payment ID",
                                       name: "stripePaymentId",
                                   },
                                   {
                                       label: "Stripe Customer ID",
                                       name: "stripeCustomerId",
                                   },
                                   {
                                       label: "Dwolla Correlation ID",
                                       name: "dwollaPaymentCorrelationId",
                                   },
                                   {
                                       label: "Dwolla Transfer ID",
                                       name: "dwollaPaymentTransferId",
                                   },
                                   {
                                       label: "Dwolla Payment Transfer On",
                                       name: "dwollaPaymentTransferCreatedOn",
                                   },
                                   {
                                       label: "Dwolla Payment Completed On",
                                       name: "dwollaPaymentTransferCompletedOn",
                                   },
                                   {
                                       label: "Dwolla Transfer Status",
                                       name: "dwollaPaymentTransferStatus",
                                       formatter: (dwollaTransferStatus) => {
                                           return DwollaTransferStatus[dwollaTransferStatus]?.label;
                                       },
                                   },
                                   {
                                       label: "Dwolla Message",
                                       name: "dwollaPaymentTransferMessage",
                                   },
                                   {
                                        label: "Supplier Payout Dwolla Transfer ID",
                                        name: "supplierPayoutDwollaTransferId",
                                   },
                                   {
                                        label: "SecurSpace Fee Payout Dwolla Transfer ID",
                                        name: "securspaceFeeDwollaTransferId",
                                   },
                                   {
                                       label: "Notes",
                                       name: "statusNotes",
                                       shouldShowField: (transaction) => {
                                           return transaction.transactionType === BookingTransactionType.SERVICE_CHARGE;
                                       }
                                   }
                               ]}
                               actionList={INVOICE_ACTIONS}
                               groupSummaryFields={[
                                   {
                                       label: "Customer Charges",
                                       name: "buyerAmount"
                                   },
                                   {
                                       label: "Payment Processor Fees",
                                       name: "paymentProcessorFees"
                                   },
                                   {
                                       label: "Partner Payouts",
                                       name: "supplierAmount"
                                   },
                                   {
                                       label: "SecurSpace Fees",
                                       name: "securspaceFees"
                                   }
                               ]}
                               account={this.state.account}
                />
            </div>
        )
    }
}

